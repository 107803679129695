import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Driver, Fridge } from "../types";
import DriverLocation from "./driver-location-card";

interface Props {
  driver: Driver;
  fridges: Fridge[];
  handleUpdateDriver: ({
    driverIds,
    fridgeIds,
  }: {
    driverIds: number[];
    fridgeIds: number[];
  }) => Promise<void>;
}

export default function DriverCard({
  driver,
  handleUpdateDriver,
  fridges,
}: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [locationsAssigned, setLocationsAssigned] = useState<number[]>(
    driver.driverFridges.map(({ id }) => id)
  );

  function locationChanged(location: number, assigned: boolean) {
    if (assigned) {
      setLocationsAssigned([...locationsAssigned, location]);
    } else {
      setLocationsAssigned(locationsAssigned.filter((loc) => loc !== location));
    }
  }

  function onCancel() {
    setExpanded(false);
    setLocationsAssigned(driver.driverFridges.map(({ id }) => id));
  }

  async function onSave() {
    setLoading(true);
    await handleUpdateDriver({
      driverIds: [driver.pk],
      fridgeIds: locationsAssigned,
    });
    setLoading(false);
    setExpanded(false);
  }
  const fullName = `
    ${driver.firstName} ${driver.lastName ?? ""} (${
    driver.driverFridges.length
  })
  `;

  const driverFridges =
    driver && driver.driverFridges && driver.driverFridges.length > 0
      ? [...driver.driverFridges].sort((a, b) => a.name.localeCompare(b.name))
      : [];

  return (
    <div className="pb-8">
      <div className="text-graphite-900 font-bold">{fullName}</div>
      <div className="text-graphite-700 pb-3 text-sm">{driver.email}</div>
      <div
        className="flex flex-row justify-between bg-white rounded-md p-5 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div>
          {driver && driver.driverFridges && driver.driverFridges.length > 0 ? (
            driverFridges.map((fridge) => (
              <div
                className="inline-flex text-sm bg-primary-600 rounded-md text-white py-1 px-2 mr-2 mb-2"
                key={fridge.id}
              >
                {fridge.name}
              </div>
            ))
          ) : (
            <div className="text-graphite-800 font-bold">
              No locations assigned
            </div>
          )}
        </div>
        <div>
          {expanded ? (
            <ChevronUpIcon width={20} height={20} color="#AFBDD8" />
          ) : (
            <ChevronDownIcon width={20} height={20} color="#AFBDD8" />
          )}
        </div>
      </div>
      {expanded && (
        <div className="">
          <div className="bg-white rounded-t-md mt-2 overflow-scroll h-[60vh]">
            {fridges.map((fridge, index) => (
              <DriverLocation
                key={index}
                fridge={fridge}
                driver={driver}
                onChange={locationChanged}
              />
            ))}
          </div>
          <div className="flex bg-white rounded-b-md justify-center w-full">
            <button
              className="py-2 border-graphite-300 border rounded-md text-sm text-graphite-700 font-medium w-full m-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={loading}
              className="bg-success-700 py-2 rounded-md text-sm text-white font-medium w-full m-2"
              onClick={onSave}
            >
              {loading ? "Loading" : "Save"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

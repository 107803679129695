import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import { REFRESH_TOKEN, TOKEN } from "../constants";
import logo from "../img/logo.png";
import { AuthContext } from "../providers/auth.provider";
import { LOGIN } from "../services/auth.service";
import { AuthResponse } from "../types";

interface LoginForm {
  email: string;
  password: string;
}

export default function Login() {
  const { setAuthState } = useContext(AuthContext);
  const [login, { loading }] = useMutation<{ tokenAuth: AuthResponse }>(LOGIN);

  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  async function handleLogin({ email, password }: LoginForm) {
    if (!email || !password) {
      alert(["Please enter a valid email and password"]);
      console.log("Please enter a valid email and password");
      return;
    }

    try {
      const { data } = await login({
        variables: { email: email.toLowerCase().trim(), password },
      });
      if (!data) {
        alert("Something went wrong, please try again.");
        return;
      }
      const { token, refreshToken, user } = data.tokenAuth;
      // if user is neither a driver nor an admin, they are not authorized to use the app
      if (!user.isDriver && !user.isAdmin) {
        alert("You are not authorized to access this app");
        return;
      }
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);

      setAuthState({ token, setAuthState, user });
    } catch (e) {
      alert("Something went wrong, please try again.");
      console.log(e);
    }
  }

  return (
    <div className="bg-black h-screen">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-16 w-auto" src={logo} alt="Your Company" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Cubbi Driver App
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  disabled={loading}
                  autoComplete="email"
                  required
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  className="block w-full px-3 rounded-md border-0 bg-white/5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                  required
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  className="block w-full px-3 rounded-md border-0 bg-white/5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin(form);
                }}
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-2.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {"Sign in"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { gql } from "@apollo/client";

export const OPEN_ALL_DOORS = gql`
  mutation openFridgeAll($id: Int!, $user: String, $originTime: DateTime) {
    openFridgeAll(
      input: { user: $user, fridgeId: $id, originTime: $originTime }
    ) {
      success
    }
  }
`;

export const OPEN_CUBBI = gql`
  mutation openCubbi($input: OpenCubbiInput!) {
    openCubbi(input: $input) {
      success
    }
  }
`;

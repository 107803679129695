import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CityDropdownFilter from "../components/city-dropdown-filter";
import DateDropdownFilter from "../components/date-dropdown-filter";
import Spinner from "../components/loading";
import DashboardProvider from "../providers/dashboard.provider";
import useGroceryOrders, { GroceryOrder } from "../services/grocery.service";

export default function OfficeStocking(): JSX.Element {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7));
    return date;
  });
  const [selectedCity, setSelectedCity] = useState("Calgary");
  const cities = ["Calgary", "Saskatoon"];
  const { data, loading, error } = useGroceryOrders({
    city: selectedCity,
    date: selectedDate.toISOString(),
  });

  function handleOrderClick(id: string) {
    navigate(`/grocery-order/${id}`);
  }

  return (
    <DashboardProvider>
      <div className="px-6 pt-4 pb-2 flex gap-4">
        <CityDropdownFilter
          items={cities}
          title={selectedCity}
          functionToSend={setSelectedCity}
          changed
          width="w-38"
          widthOfDropdown="w-48"
          height="h-auto"
        />
        <DateDropdownFilter
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <div className="p-6">
        {loading && (
          <div className="h-16">
            <Spinner />
          </div>
        )}
        {error && <div>Error loading orders</div>}
        {data && data.driverAppGroceryOrders.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data?.driverAppGroceryOrders.map((order: GroceryOrder) => (
              <div
                key={order.id}
                onClick={() => handleOrderClick(order.id)}
                className="p-4 bg-white rounded-lg shadow cursor-pointer hover:bg-gray-50 hover:scale-[1.02] transition-all duration-200"
              >
                <div className="flex justify-between items-start">
                  <div className="space-y-2">
                    <h3 className="font-medium text-lg">{order.fridge.name}</h3>
                    <p className="text-gray-500">Cubbi Order #{order.id}</p>
                  </div>
                  <div className="text-gray-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-lg font-bold">
            No orders found for this week starting on{" "}
            {selectedDate.toLocaleDateString("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
            })}
          </div>
        )}
      </div>
    </DashboardProvider>
  );
}

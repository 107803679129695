import { useNavigate, useParams } from "react-router-dom";
import { useTeamOrder } from "../services/team-order.service";
import Spinner from "../components/loading";
import DriverDashboardProvider from "../providers/driver-dashboard.provider";
import { useMutation } from "@apollo/client";
import { GroupOrder } from "../types";
import { UPDATE_TEAM_ORDER } from "../services/team-order.service";
import { useState } from "react";

type Mutation = {
  updateGroupOrder: {
    groupOrder: GroupOrder;
  };
};

export default function DriverPickupById() {
  const { id } = useParams();

  const { order, loading } = useTeamOrder(+id!);
  const [updateGroupOrder] = useMutation<Mutation>(UPDATE_TEAM_ORDER);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const navigate = useNavigate();

  if (loading) return <Spinner />;
  if (!order) return <p className="text-center p-4">Order not found</p>;

  const handleMarkAsDelivered = async () => {
    setLoadingUpdate(true);
    try {
      const data = await updateGroupOrder({
        variables: {
          id: order.id,
          input: {
            delivered: true,
          },
        },
      });
      if (data) {
        setLoadingUpdate(false);
        navigate("/pickups");
      }
    } catch (e) {
      setLoadingUpdate(false);
    }
  };

  const renderOrderItems = () => {
    return order.bookings?.map((booking, index) => {
      const fullname = `${order.host.firstName} ${order.host.lastName}`;
      const name = fullname.trim() === "" ? `Guest ${index + 1}` : fullname;
      const qty = booking.bookingitemsSet?.reduce(
        (total, item) => total + item.quantity,
        0
      );
      return (
        <div key={booking.id || index} className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold mb-1">{name}</h3>
            <p className="text-right text-sm text-gray-600 mb-2">
              {`Qty: ${qty}`}
            </p>
          </div>
          {booking.bookingitemsSet.map((item, itemIndex) => (
            <div key={item.id || itemIndex} className="mb-2">
              <p className="font-base">
                {item.product?.name} x{item.quantity}
              </p>
              <p className="text-sm text-gray-600">
                {item.addons?.length
                  ? item.addons.map((addon) => addon.name).join(", ")
                  : "No addons"}
              </p>
            </div>
          ))}
        </div>
      );
    });
  };

  const calculateTotalItems = () => {
    return order.bookings?.reduce((total, booking) => {
      return (
        total +
        booking.bookingitemsSet.reduce((bookingTotal, item) => {
          return bookingTotal + item.quantity;
        }, 0)
      );
    }, 0);
  };

  if (!order || !order.deliveryDate) return null;

  return (
    <DriverDashboardProvider>
      <div className="max-w-3xl mx-auto p-6">
        <div className="flex justify-between mb-6">
          <div className="bg-white p-4 rounded-lg shadow w-1/2 mr-2">
            <p className="mb-1 space-x-2">
              <span className="font-base">Cubbi Order:</span>
              <span className="font-medium">{order.id}</span>
            </p>
            <p className="mb-1 space-x-2">
              <span className="font-base">Pod Name:</span>
              <span className="font-medium">{order.fridge.name}</span>
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow w-1/2 ml-2">
            <p className="mb-1 space-x-2">
              <span className="font-base">Host Name:</span>
              <span className="font-medium">
                {order.host.firstName} {order.host.lastName}
              </span>
            </p>
            <p className="mb-1 space-x-2">
              <span className="font-base">Phone Number:</span>
              <span className="font-medium">{order.host.phone ?? "N/A"}</span>
            </p>
            <p className="space-x-2">
              <span className="font-base">Email:</span>
              <span className="font-medium">{order.host.email}</span>
            </p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">Items</h2>
            <span className="text-lg flex space-x-2">
              <p className="font-medium">Total Item Quantity:</p>
              <p className="text-indigo-500  font-bold">
                {calculateTotalItems()}
              </p>
            </span>
          </div>
          <hr className="mb-4" />
          <div className="max-h-[656px] overflow-y-auto">
            {renderOrderItems()}
          </div>
        </div>
        <div className="py-4" />
        <div className="bg-white p-6 rounded-lg shadow">
          <button
            disabled={loading}
            className="cursor-pointer flex items-center text-white bg-blue-600 hover:bg-blue-700 justify-center px-3 py-3 rounded-md w-full border border-gray"
            onClick={handleMarkAsDelivered}
          >
            {loading || loadingUpdate ? "Loading..." : "Mark as delivered"}
          </button>
        </div>
      </div>
    </DriverDashboardProvider>
  );
}

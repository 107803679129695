import { useMemo, useState } from "react";
import DashboardProvider from "../providers/dashboard.provider";
import { useGroupOrders } from "../services/team-order.service";
import { GroupOrder } from "../types";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Spinner from "../components/loading";
import CityDropdownFilter from "../components/city-dropdown-filter";
import { useNavigate } from "react-router-dom";
import { classNames } from "../utils/classNames";
import moment from "moment";

const deliveryType = "doordash";

export default function AdminDoorDash() {
  const [selectedCity, setSelectedCity] = useState("Calgary");
  const [today, setToday] = useState(moment().set({ hours: 9, minutes: 0 }));
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const { orders, loading } = useGroupOrders(
    selectedCity,
    today.toISOString(),
    deliveryType
  );

  const cities = ["Calgary", "Saskatoon"];

  function handleNavigateToOrder(id: number) {
    navigate(`/doordash/${id}`);
  }

  function selectTomorrow() {
    if (selected !== 1) {
      const newDate = today.add(1, "days");
      setToday(newDate);
      setSelected(1);
    }
  }

  function selectToday() {
    if (selected !== 0) {
      const newDate = today.subtract(1, "days");
      setToday(newDate);
      setSelected(0);
    }
  }

  return (
    <DashboardProvider>
      <div className="p-4">
        <div className="mb-4">
          <CityDropdownFilter
            items={cities}
            title={selectedCity}
            functionToSend={setSelectedCity}
            changed={true}
            width="w-38"
            widthOfDropdown="w-48"
            height="h-auto"
          />
        </div>
        {loading && <Spinner />}
        <div className="flex flex-row text-lg font-bold pb-4">
          <div
            className={classNames(selected === 0 ? "underline" : "")}
            onClick={selectToday}
          >
            Today
          </div>
          <div className="pl-4" />
          <div
            className={classNames(selected === 1 ? "underline" : "")}
            onClick={selectTomorrow}
          >
            Tomorrow
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {orders?.map((order: GroupOrder) => (
            <div
              onClick={() => handleNavigateToOrder(order.id)}
              key={order.id}
              className="bg-white shadow-md rounded-lg p-4 cursor-pointer"
            >
              <div className="flex items-center">
                <div className="flex-grow">
                  <h2 className="text-[16px] font-semibold mb-2">
                    {order.fridge.name}
                  </h2>
                  <div className="flex flex-col space-y-4">
                    <p className="text-sm text-gray-600">
                      DoorDash Number: {order.delivery.id}
                    </p>
                    <p className="text-sm text-gray-600">
                      Cubbi Order #: {order.id}
                    </p>
                    <p className="text-sm text-gray-600">
                      Delivery time:{" "}
                      {new Date(
                        order.delivery.startDatetime
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {(() => {
                        const endTime = new Date(order.delivery.startDatetime);
                        endTime.setMinutes(endTime.getMinutes() + 15);
                        return endTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                      })()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center ml-4">
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DashboardProvider>
  );
}

import { gql, useQuery } from "@apollo/client";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";
import { Fridge, QueryResult, QueryResultPagination } from "../types";

export const GET_FRIDGES = gql`
  query driverFridges(
    $drivers: [Int]
    $search: String
    $first: Int
    $after: String
  ) {
    driverFridges(
      drivers: $drivers
      search: $search
      first: $first
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          isOnline
          location {
            city
          }
          drivers {
            edges {
              node {
                id
                lastName
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DRIVERS = gql`
  query usersQuery($isDriver: Boolean!, $adminCity: String!) {
    usersQuery(isDriver: $isDriver, adminCity: $adminCity) {
      edges {
        node {
          id
          firstName
          lastName
          email
          driverOrganizations {
            id
          }
        }
      }
    }
  }
`;

export const GET_DRIVER_LOCATIONS = gql`
  query usersQuery($email: String!) {
    usersQuery(email: $email) {
      edges {
        node {
          id
          firstName
          lastName
          email
          driverOrganizations {
            id
          }
        }
      }
    }
  }
`;
export const UPDATE_FRIDGES = gql`
  mutation updateMutation($input: FridgeBulkInput!) {
    updateFridgeBulk(input: $input) {
      fridges {
        id
        name
        drivers {
          edges {
            node {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

export function useFridges({
  drivers,
  city,
}: {
  drivers?: number[];
  city?: string;
}) {
  const { loading, error, data, refetch } = useQuery<{
    driverFridges: QueryResult<Fridge>;
  }>(GET_FRIDGES, {
    variables: { city, drivers },
  });

  const fridges =
    data?.driverFridges.edges && getNodeDataFromEdges(data.driverFridges.edges);
  return { fridges, loading, error, refetch };
}

export function useDriverFridges({
  drivers,
  search,
  first = 20,
}: {
  city?: string;
  drivers?: number[];
  search?: string;
  first: number;
}) {
  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery<{
    driverFridges: QueryResultPagination<Fridge>;
  }>(GET_FRIDGES, {
    variables: { drivers, search, first },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const fridges =
    data?.driverFridges.edges && getNodeDataFromEdges(data.driverFridges.edges);
  const hasNextPage = data?.driverFridges.pageInfo?.hasNextPage;
  const hasPreviousPage = data?.driverFridges.pageInfo?.hasPreviousPage;
  const endCursor = data?.driverFridges.pageInfo?.endCursor;
  return {
    fridges,
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore,
    hasNextPage,
    hasPreviousPage,
    endCursor,
  };
}

export function useGetDrivers(adminCity: string) {
  const { loading, error, data } = useQuery(GET_DRIVERS, {
    variables: { isDriver: true, adminCity },
  });
  const users = getNodeDataFromEdges(data?.usersQuery);
  return { users, loading, error };
}

export function useGetDriverLocations(email?: string) {
  const { loading, error, data } = useQuery(GET_DRIVER_LOCATIONS, {
    variables: { email },
  });
  const users = getNodeDataFromEdges(data?.usersQuery);
  return { users, loading, error };
}

export const updateFridgesQuery = (
  previousResult: any,
  { fetchMoreResult }: { fetchMoreResult: any }
) => {
  if (!fetchMoreResult) return previousResult;
  return {
    driverFridges: {
      ...fetchMoreResult.driverFridges,
      edges: [
        ...previousResult.driverFridges.edges,
        ...fetchMoreResult.driverFridges.edges,
      ],
    },
  };
};

import { Dialog } from "@headlessui/react";
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  GET_UNDELIVERED_BOOKINGS,
  UPDATE_BOOKING,
} from "../services/bookings.service";

type IssueType = {
  id: number;
  label: string;
};

const ISSUE_TYPES: IssueType[] = [
  { id: 1, label: "App Issue" },
  { id: 2, label: "Act of God" },
  { id: 3, label: "User Requested Cancellation" },
  { id: 4, label: "Inventory - Vendor error" },
  { id: 5, label: "Inventory - Internal error" },
  { id: 6, label: "Quality - Vendor error" },
  { id: 7, label: "Quality - Internal error" },
];

interface ReportIssueModalProps {
  isOpen: boolean;
  onClose: () => void;
  bookingId: number;
}

export function ReportIssueModal({
  isOpen,
  onClose,
  bookingId,
}: ReportIssueModalProps) {
  const [issueType, setIssueType] = useState<number | undefined>();
  const [comments, setComments] = useState<string | undefined>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [updateBooking] = useMutation(UPDATE_BOOKING, {
    refetchQueries: [GET_UNDELIVERED_BOOKINGS],
  });

  const handleSubmit = async () => {
    if (!issueType || !bookingId) return;

    try {
      await updateBooking({
        variables: {
          id: bookingId,
          input: {
            cantDeliver: true,
            cantDeliverCategory: issueType,
            deliveryComments: comments || "",
            // deliveredAt: new Date().toISOString(),
          },
        },
      });

      onClose();
    } catch (error) {
      console.error("Failed to update booking:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white rounded-2xl p-6 max-w-md w-full">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-2xl font-bold text-veggie">
              REPORT AN ISSUE
            </Dialog.Title>
            <button onClick={onClose}>
              <XMarkIcon className="h-6 w-6 text-gray-400" />
            </button>
          </div>

          <div className="space-y-4">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type of issue
              </label>
              <div
                className="w-full border border-gray-300 rounded-md px-3 py-2 flex justify-between items-center cursor-pointer"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span className={!issueType ? "text-gray-400" : ""}>
                  {issueType
                    ? ISSUE_TYPES.find((issue) => issue.id === issueType)?.label
                    : "Select an issue category"}
                </span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>

              {isDropdownOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                  {ISSUE_TYPES.map((issue) => (
                    <div
                      key={issue.id}
                      className={`px-3 py-2 cursor-pointer hover:bg-gray-50 ${
                        issue.id === issueType
                          ? "bg-emerald-50 text-emerald-700"
                          : ""
                      }`}
                      onClick={() => {
                        setIssueType(issue.id);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {issue.label}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Any comments for us?
              </label>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="More details about the issue"
                className="w-full border border-gray-300 rounded-md px-3 py-2 h-32 resize-none focus:outline-none focus:ring-2 focus:ring-emerald-500"
              />
            </div>

            <button
              onClick={handleSubmit}
              disabled={!issueType}
              className="w-full bg-veggie text-white py-3 rounded-3xl hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Confirm
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

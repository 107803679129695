import { gql, useQuery } from "@apollo/client";
import { GroupOrder } from "../types";

export const DRIVER_APP_GROUPORDERS = gql`
  query driverAppGroupOrders(
    $city: String
    $date: DateTime
    $deliveryMethod: String
  ) {
    driverAppGroupOrders(
      city: $city
      date: $date
      deliveryMethod: $deliveryMethod
    ) {
      id
      fridge {
        name
      }
      delivery {
        id
        startDatetime
        trackingUrl
      }
    }
  }
`;

export function useGroupOrders(
  city: string,
  date: string,
  deliveryMethod: string
) {
  const { data, loading, error, refetch } = useQuery<{
    driverAppGroupOrders: GroupOrder[];
  }>(DRIVER_APP_GROUPORDERS, {
    variables: {
      city,
      date,
      deliveryMethod,
    },
  });
  const orders = data?.driverAppGroupOrders;
  return { orders, data, loading, error, refetch };
}

export const TEAM_ORDER = gql`
  query groupOrder($id: Int!) {
    groupOrder(id: $id) {
      id
      title
      deliveryDate
      host {
        firstName
        lastName
        email
        phone
      }
      fridge {
        name
      }
      delivery {
        id
        startDatetime
        trackingUrl
      }
      bookings {
        owner {
          firstName
          lastName
        }
        bookingitemsSet {
          product {
            name
          }
          quantity
          addons {
            name
          }
        }
      }
    }
  }
`;

export function useTeamOrder(id?: number) {
  const { data, loading, error, refetch } = useQuery<{
    groupOrder: GroupOrder;
  }>(TEAM_ORDER, {
    variables: {
      id,
    },
    skip: !id,
  });
  const order = data?.groupOrder;
  return { order, data, loading, error, refetch };
}

export const UPDATE_TEAM_ORDER = gql`
  mutation updateGroupOrder(
    $id: Int!
    $input: GroupOrderInput!
    $token: String
    $code: String
  ) {
    updateGroupOrder(id: $id, input: $input, token: $token, code: $code) {
      ok
      groupOrder {
        id
        title
        description
        host {
          id
          firstName
          lastName
          email
        }
        fridge {
          id
          name
        }
        limitPerGuest
        deliveryDate
        note
        rating
        orderDeadline
        mealTypes
      }
    }
  }
`;

import React from "react";
import { ReactNode } from "react";
import { useState, useEffect } from "react";

interface Props {
  children: ReactNode;
}

export default function NetworkProvider({ children }: Props) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);
  return (
    <>
      {!isOnline && (
        <div className={"bg-red-50 text-red-700 px-4 font-bold"}>
          You are currently offline, please refresh or check connection.
        </div>
      )}
      {children}
    </>
  );
}

import { Moment } from "moment";
import { Booking } from "../types";
import { convertCubbiPosition } from "../utils/convert-cubbi-position";
import BookingCard from "./booking-card";

interface Props {
  bookings: Booking[];
  today: Moment;
  refetch?: () => void;
  isMobile: boolean;
  isGroupOrder: boolean;
}

export default function BookingCards({
  bookings,
  today,
  refetch,
  isMobile,
  isGroupOrder,
}: Props) {
  const cubbi = convertCubbiPosition(bookings[0].cubbi.position);
  const result = bookings.reduce((accumulator: Booking[], current: Booking) => {
    let exists = accumulator.find((item: Booking) => {
      return item.id === current.id;
    });
    if (!exists) {
      accumulator = accumulator.concat(current);
    }
    return accumulator;
  }, []);
  return (
    <div className="min-w-[342px] py-4">
      <span className="flex justify-center">{cubbi}</span>
      {result.map((e, i) => {
        return (
          <div className="flex flex-col" key={i}>
            <BookingCard
              booking={e}
              today={today}
              refetch={refetch}
              bookings={result.length}
              isMobile={isMobile}
              isGroupOrder={isGroupOrder}
            />
          </div>
        );
      })}
    </div>
  );
}

import { useParams } from "react-router-dom";
import { useTeamOrder } from "../services/team-order.service";
import DriverDashboardProvider from "../providers/driver-dashboard.provider";

export default function DriverDoorDashById() {
  const { id } = useParams();

  const { order, loading } = useTeamOrder(+id!);

  if (loading) return <p className="text-center p-4">Loading...</p>;

  if (!order) return <p className="text-center p-4">Order not found</p>;

  const renderOrderItems = () => {
    return order.bookings?.map((booking, index) => {
      const fullname = `${order.host.firstName} ${order.host.lastName}`;
      const name = fullname.trim() === "" ? `Guest ${index + 1}` : fullname;
      const qty = booking.bookingitemsSet?.reduce(
        (total, item) => total + item.quantity,
        0
      );
      return (
        <div key={booking.id || index} className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold mb-1">{name}</h3>
            <p className="text-right text-sm text-gray-600 mb-2">
              {`Qty: ${qty}`}
            </p>
          </div>
          {booking.bookingitemsSet.map((item, itemIndex) => (
            <div key={item.id || itemIndex} className="mb-2">
              <p className="font-base">
                {item.product?.name} x{item.quantity}
              </p>
              <p className="text-sm text-gray-600">
                {item.addons?.length
                  ? item.addons.map((addon) => addon.name).join(", ")
                  : "No addons"}
              </p>
            </div>
          ))}
        </div>
      );
    });
  };

  const calculateTotalItems = () => {
    return order.bookings?.reduce((total, booking) => {
      return (
        total +
        booking.bookingitemsSet.reduce((bookingTotal, item) => {
          return bookingTotal + item.quantity;
        }, 0)
      );
    }, 0);
  };
  function handleOpenInNewTab(url: string) {
    window.open(`${url}`, "_blank", "noopener, noreferrer");
  }

  if (!order || !order.deliveryDate) return null;

  return (
    <DriverDashboardProvider>
      <div className="max-w-3xl mx-auto p-6">
        <div className="flex justify-between mb-6">
          <div className="bg-white p-4 rounded-lg shadow w-1/2 mr-2">
            <p className="mb-1 space-x-2">
              <span className="font-base">DoorDash Number:</span>
              <span className="font-medium">{order.delivery.id}</span>
            </p>
            <p className="mb-1 space-x-2">
              <span className="font-base">Cubbi Order:</span>
              <span className="font-medium">{order.id}</span>
            </p>
            <p className="mb-1 space-x-2">
              <span className="font-base">Pod Name:</span>
              <span className="font-medium">{order.fridge.name}</span>
            </p>
            <p className="mb-1 space-x-2">
              <span className="font-base">Delivery Time:</span>{" "}
              <span className="font-medium">
                {new Date(order.delivery.startDatetime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                -{" "}
                {(() => {
                  const endTime = new Date(order.delivery.startDatetime);
                  endTime.setMinutes(endTime.getMinutes() + 15);
                  return endTime.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                })()}
              </span>
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow w-1/2 ml-2">
            <p className="mb-1 space-x-2">
              <span className="font-base">Host Name:</span>
              <span className="font-medium">
                {order.host.firstName} {order.host.lastName}
              </span>
            </p>
            <p className="mb-1 space-x-2">
              <span className="font-base">Phone Number:</span>
              <span className="font-medium">{order.host.phone ?? "N/A"}</span>
            </p>
            <p className="space-x-2 mb-1">
              <span className="font-base">Email:</span>
              <span className="font-medium">{order.host.email}</span>
            </p>
            {order && order.delivery && (
              <p className="space-x-2">
                <span
                  className="font-base underline text-primary-700"
                  onClick={() => handleOpenInNewTab(order.delivery.trackingUrl)}
                >
                  Doordash tracking url
                </span>
              </p>
            )}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">Items</h2>
            <h2 className="text-lg font-bold">
              Total Item Quantity: {calculateTotalItems()}
            </h2>
          </div>
          <hr className="mb-4" />
          <div className="max-h-[656px] overflow-y-auto">
            {renderOrderItems()}
          </div>
        </div>
      </div>
    </DriverDashboardProvider>
  );
}

import moment, { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import { Booking } from "../types";
import { classNames } from "../utils/classNames";
import { getItemSum } from "../utils/sum-items";
import EmptyCubbi from "./empty-cubbi";

interface Props {
  bookings: Booking[];
  today: Moment;
  setSelectedCubbi: Dispatch<
    SetStateAction<{
      id: String;
      groupOrder: boolean;
    }>
  >;
}

export default function DeterminingCubbi({
  bookings,
  today,
  setSelectedCubbi,
}: Props) {
  if (bookings.length === 0) {
    return <EmptyCubbi />;
  }

  const notDelivered = bookings.filter((e) => !e.deliveredAt); //Deliver
  const pastBookings = bookings.filter(
    (e) =>
      moment(e.endAt).isSameOrBefore(today, "day") &&
      e.deliveredAt &&
      !e.completed //Remove or Move
  );

  const deliveredBookings = bookings.filter(
    (e) => moment(e.endAt).isAfter(today, "day") && e.deliveredAt //delivered
  );

  const isCommunal = bookings[0] && bookings[0].cubbi.communal;
  const removeExists = pastBookings.find((i) => i.cubbi.use === "A_4");
  return (
    <div
      className={classNames(
        isCommunal ? "w-full" : "",
        removeExists
          ? "bg-destructive-50 border-destructive-500"
          : pastBookings.length
          ? "bg-warning-50 border-warning-500"
          : notDelivered.length
          ? "bg-blue-50 border-blue-600"
          : deliveredBookings.length > 0
          ? "bg-success-50 border-success-600"
          : "bg-graphite-50 border-none",
        "flex border-2  rounded-md  w-[165px] items-center justify-center relative py-6"
      )}
      style={{ minHeight: "6rem" }}
      onClick={() =>
        setSelectedCubbi({ id: bookings[0].cubbi.position, groupOrder: false })
      }
    >
      {isCommunal && (
        <div className="font-bold pl-2 text-graphite-600 text-sm flex absolute top-0 left-0">
          Communal
        </div>
      )}
      <div className="flex flex-col items-center">
        {deliveredBookings.map((e, i) => {
          return (
            <div className="flex flex-row items-center" key={i}>
              <div className="text-success-900 font-bold">Delivered</div>
              <div className="font-base text-success-900">
                &nbsp;{"#" + e.id}
              </div>
              <div className="font-base text-success-900">
                {" - " + getItemSum(e)}
              </div>
            </div>
          );
        })}
        {notDelivered.map((e, i) => {
          return (
            <div className="flex flex-row items-center" key={i}>
              <div className="font-bold text-graphite-900">Deliver</div>
              <div className="font-base text-graphite-900">
                &nbsp;{"#" + e.id}
              </div>
              <div className="font-base text-graphite-900 pl-1">
                {" - " + getItemSum(e)}
              </div>
            </div>
          );
        })}
        {pastBookings.map((e, i) => {
          return (
            <div className="flex flex-row items-center" key={i}>
              <div className="font-bold text-graphite-900">
                {pastBookings[i].cubbi.use === "A_4" ? "Remove" : "Move"}
              </div>
              <div className="font-base text-graphite-900">
                &nbsp;{"#" + e.id}
              </div>
              <div className="font-base text-graphite-900 pl-1">
                {" - " + getItemSum(e)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import { ReactNode } from "react";
import AdminNavigation from "../components/admin-navigation";

interface Props {
  children: ReactNode;
}

export default function DashboardProvider({ children }: Props) {
  return (
    <>
      <AdminNavigation />
      {children}
    </>
  );
}

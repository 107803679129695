import { RouterProvider } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "./auth.provider";
import ADMIN_ROUTER from "../admin-router";
import DRIVER_ROUTER from "../driver-router";
import LOGIN_ROUTER from "../login-router";
import { useMe } from "../services/user.service";
import { TOKEN } from "../constants";
import Loading from "../components/loading";

export default function RouteProvider() {
  const { user, setAuthState } = useContext(AuthContext);
  const token = localStorage.getItem(TOKEN);
  const { me } = useMe();

  useEffect(() => {
    function determineInitialState() {
      if (!token || token === "null") {
        setAuthState({ user: null, setAuthState, token: null });
        localStorage.clear();
        return;
      }
      if (me) {
        setAuthState({ user: me, setAuthState, token });
      }
    }
    determineInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  if (token && !user) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <RouterProvider
      router={
        !user ? LOGIN_ROUTER : user.isAdmin ? ADMIN_ROUTER : DRIVER_ROUTER
      }
    />
  );
}
//

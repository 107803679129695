import { useLocation, useNavigate } from "react-router-dom";
import { Booking, Fridge } from "../types";
import { convertCubbiPosition } from "../utils/convert-cubbi-position";
import BookingCard from "../components/booking-card";
import { useContext, useMemo, useState } from "react";
import moment from "moment";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import { OPEN_CUBBI } from "../services/fridge.service";
import { AuthContext } from "../providers/auth.provider";

interface fromAdminView {
  bookings: Booking[];
  fridge: Fridge;
  isGroupOrder: boolean;
}
export default function AdminBookingCards() {
  const { user } = useContext(AuthContext);

  const [openCubbi, { loading }] = useMutation<{
    openCubbi: { success: boolean };
  }>(OPEN_CUBBI);

  const location = useLocation();
  const state = location.state as fromAdminView;
  const { bookings: bookingsFromRoute, isGroupOrder } = state;
  const [bookings, setBookings] = useState<Booking[]>(bookingsFromRoute);
  const todayAt0 = useMemo(() => {
    return moment().set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }, []);

  function updateBooking(position: number | undefined, action: string) {
    let tempBookings = bookings;
    console.log(
      "updating booking at position:",
      position,
      " With action ",
      action
    );
    if (bookings && position !== undefined && bookings[position]) {
      if (action === "removed") {
        tempBookings[position].completed = true;
      } else if (action === "moved") {
        tempBookings[position].completed = true;
      } else if (action === "deliver") {
        tempBookings[position].deliveredAt = moment().toISOString();
      }
      console.log(tempBookings);
      setBookings([...tempBookings]);
    }
  }
  const cubbi = convertCubbiPosition(bookings[0].cubbi.position);
  const navigate = useNavigate();

  async function onPressOpenCubbi() {
    if (!user) {
      alert("You must be logged in to open a cubbi");
      return;
    }
    if (!bookings) {
      alert("No booking id");
      return;
    }
    const cubbiId = bookings[0].cubbi.id;
    const bookingId = bookings[0].id;
    const email = user.email;
    const originTime = moment().toISOString(true);
    try {
      await openCubbi({
        variables: {
          input: {
            cubbiId,
            user: email,
            originTime,
            bookingId,
          },
        },
      });
    } catch {
      alert("Failed to open cubbi");
    }
  }

  return (
    <div className="bg-white min-h-screen pb-40">
      <header className="flex flex-row justify-between items-center py-4 px-4 border-b pb-2 border-graphite-200">
        <ChevronLeftIcon
          className="w-6 h-6 text-graphite-600"
          onClick={() => navigate(-1)}
        />
        <div className="flex flex-row">
          <div className="font-bold text-lg">{cubbi}</div>
        </div>
        <div />
      </header>
      <div className="flex justify-center flex-col px-4">
        {bookings.map((booking, i) => {
          return (
            <div className="flex flex-col justify-center" key={booking.id}>
              <BookingCard
                booking={booking}
                today={todayAt0}
                bookings={bookings.length}
                isMobile={true}
                updateLocalBookings={updateBooking}
                position={i}
                isGroupOrder={isGroupOrder}
              />
            </div>
          );
        })}
      </div>
      <div className="fixed bottom-4 left-0 w-full bg-white  border-graphite-200 px-4 py-3">
        <button
          onClick={onPressOpenCubbi}
          disabled={loading}
          className="flex items-center text-white bg-[#16A951] justify-center px-3 py-3 rounded-md w-full  md:w-[342px] lg:w-[442px] border border-gray"
        >
          {loading ? "Opening..." : "Open Pod"}
        </button>
      </div>
    </div>
  );
}

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export default function Checkbox({ checked, onChange }: Props) {
  return (
    <input
      className="w-5 h-5 text-primary-700 rounded border border-primary-600 bg-primary-100 cursor-pointer"
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
}

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/classNames";
import { Dispatch, SetStateAction } from "react";
import moment from "moment";
interface Props {
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  width?: string;
  widthOfDropdown?: string;
  height?: string;
}

export default function DateDropdownFilter({
  selectedDate,
  setSelectedDate,
  width = "w-48",
  widthOfDropdown = "w-56",
  height = "h-auto",
}: Props): JSX.Element {
  // Generate next 4 Mondays
  const getNextMondays = (): Date[] => {
    const mondays: Date[] = [];
    const today = new Date();

    let nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7));

    for (let i = 0; i < 4; i++) {
      mondays.push(new Date(nextMonday));
      nextMonday.setDate(nextMonday.getDate() + 7);
    }

    return mondays;
  };

  const formatDate = (date: Date): string => {
    return moment(date).format("MMMM Do");
  };

  return (
    <Menu as="div" className={`${width} relative inline-block text-left`}>
      <div>
        <Menu.Button className="inline-flex w-full justify-between rounded-3xl bg-graphite-800 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {formatDate(selectedDate)}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-50 mt-2 ${widthOfDropdown} ${height} origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {getNextMondays().map((date, i) => (
              <Menu.Item key={date.toISOString()}>
                {({ active }) => (
                  <div
                    onClick={() => setSelectedDate(date)}
                    className={classNames(
                      i > 0 ? "border-t" : "",
                      "flex justify-between flex-row items-center cursor-pointer hover:bg-gray-100"
                    )}
                  >
                    <p
                      className={classNames(
                        selectedDate.toDateString() === date.toDateString()
                          ? "text-primary-700"
                          : "text-graphite-900",
                        "block px-4 py-2 text-sm font-semibold"
                      )}
                    >
                      {formatDate(date)}
                    </p>
                    {selectedDate.toDateString() === date.toDateString() && (
                      <div className="px-4">
                        <CheckIcon className="text-primary-700 h-4 w-4" />
                      </div>
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

import { useState } from "react";
import { Driver, Fridge } from "../types";
import Checkbox from "./checkbox";

interface Props {
  fridge: Fridge;
  driver: Driver;
  onChange: (location: number, checked: boolean) => void;
}

export default function DriverLocation({ fridge, driver, onChange }: Props) {
  const drivers = fridge.drivers.edges.map(({ node }) => node);
  const [checked, setChecked] = useState<boolean>(
    drivers.find(({ id }) => id === driver.id) ? true : false
  );

  return (
    <div className="flex flex-row justify-between border-b border-grey-600 py-3 col-span-1">
      <div className="flex flex-col pl-5">
        <div className="text-graphite-900 text-base font-bold">
          {fridge.name}
        </div>
        {drivers.length > 0 ? (
          <div className="text-sm text-graphite-600">
            {drivers
              .filter((d) => driver.id !== d.id)
              .map((d, index) =>
                (index === 0 ? "" : ", ") + d.firstName
                  ? `${d.firstName} ${d.lastName ?? ""}`
                  : d.email
              )}
          </div>
        ) : (
          <div className="text-sm text-destructive-600">Unassigned</div>
        )}
      </div>
      <div className="mr-5">
        <Checkbox
          checked={checked}
          onChange={(value) => {
            setChecked(value);
            onChange(fridge.id, value);
          }}
        />
      </div>
    </div>
  );
}

import { Booking } from "../types";
import { useMutation } from "@apollo/client";
import {
  ADMIN_ORDERS,
  GET_UNDELIVERED_BOOKINGS,
  UPDATE_BOOKING,
} from "../services/bookings.service";
import { useState } from "react";
import moment from "moment";
import { ReportIssueModal } from "./report-issue-modal";
import { convertCubbiPosition } from "../utils/convert-cubbi-position";

interface DeliveryCardProps {
  booking: Booking;
  refetch?: () => void;
}

export function DeliveryCard({ booking, refetch }: DeliveryCardProps) {
  const [updateBooking] = useMutation<{ updateBooking: { ok: boolean } }>(
    UPDATE_BOOKING,
    {
      refetchQueries: [GET_UNDELIVERED_BOOKINGS],
    }
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);

  const isToday = moment(booking.startAt).isSame(moment(), "day");
  const bgColorClass = isToday ? "bg-veggie" : "bg-peaches";
  const dateBgColorClass = isToday ? "bg-[#E8F0F8]" : "bg-peaches";
  const dateTextColorClass = isToday ? "text-[#1D3A5B]" : "text-white";
  async function handleMarkAsDelivered() {
    setLoading(true);
    try {
      const today = moment();
      await updateBooking({
        variables: {
          id: booking.id,
          input: {
            deliveredAt: today.toISOString(true),
          },
        },
      });
      if (refetch) {
        refetch();
      }
    } catch (e) {
      alert("Error marking as delivered");
    }
    setLoading(false);
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 w-full sm:w-[343px] min-h-[372px] h-full relative overflow-hidden">
      <div className={`absolute left-0 top-0 bottom-0 w-2 ${bgColorClass}`} />

      <div className="pl-2 h-full flex flex-col">
        <div className="mb-4">
          <div className="flex justify-between items-center text-[#1D3A5B] gap-1">
            <h3 className="font-bold text-lg">
              {booking.cubbi?.fridge?.name || "Unnamed Fridge"}
            </h3>
            <div
              className={`${dateBgColorClass} ${dateTextColorClass} px-2 py-1 rounded font-medium text-sm uppercase`}
            >
              {moment(booking.startAt).format("MMM D")}
            </div>
          </div>
          <div className="text-[#1D3A5B] font-bold mt-2 text-base">
            {booking.bookingType === "catering"
              ? "Catering"
              : convertCubbiPosition(booking.cubbi?.position)}
          </div>
        </div>

        <div className="mb-4 flex-grow">
          <div className="text-gray-600 mb-2">Items</div>
          {booking.bookingItems.map((item) => (
            <div key={item.productId} className="flex justify-between">
              <span>{item.name}</span>
              <span>× {item.quantity}</span>
            </div>
          ))}
        </div>

        <div className="mb-4 grid grid-cols-2 gap-2">
          <div>
            <div className="flex gap-1">
              <span className="text-[#212322] font-medium">Name:</span>
              <span className="font-light">{`${booking.owner.firstName} ${booking.owner.lastName}`}</span>
            </div>
          </div>
          <div>
            <div className="flex gap-1">
              <span className="text-[#212322] font-medium">Booking ID:</span>
              <span className="font-light">{booking.id}</span>
            </div>
          </div>
          <div>
            <div className="flex gap-1">
              <span className="text-[#212322] font-medium">Order ID:</span>
              <span className="font-light">{booking.orderId}</span>
            </div>
          </div>
          <div>
            <div className="flex gap-1">
              <span className="text-[#212322] font-medium">Type:</span>
              <span className="font-light">
                {booking.bookingType === "catering"
                  ? "Catering"
                  : booking.cubbi.use === "A_4"
                  ? "Grab N' Go"
                  : "Pre Order"}
              </span>
            </div>
          </div>
          {booking.payment?.payee && (
            <div className="col-span-2">
              <div className="flex gap-1">
                <span className="text-[#212322] font-medium">Payee:</span>
                <span className="font-light">
                  {`${booking.payment.payee.firstName} ${booking.payment.payee.lastName}`}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="space-y-2 mt-auto">
          <button
            disabled={loading}
            onClick={handleMarkAsDelivered}
            className={`w-full ${bgColorClass} text-white py-2 rounded-md hover:opacity-80 transition-opacity disabled:opacity-50`}
          >
            {loading ? "Loading..." : "Deliver"}
          </button>
          <button
            onClick={() => setIsReportModalOpen(true)}
            className="w-full bg-white text-gray-700 py-2 rounded-md border border-gray-200 hover:bg-gray-200 transition-colors"
          >
            Can't Deliver?
          </button>
        </div>

        <ReportIssueModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          bookingId={booking.id}
        />
      </div>
    </div>
  );
}

import { classNames } from "../utils/classNames";

interface Props {
  openAllDoors: () => void;
  markAllAsComplete: boolean;
  handleMarkAllAsComplete: () => void;
  loading: boolean;
}

export default function CubbiButtons({
  openAllDoors,
  markAllAsComplete,
  handleMarkAllAsComplete,
  loading = false,
}: Props) {
  return (
    <div className="py-6 flex flex-col items-center justify-center max-h-screen w-[342px]">
      <div
        className="cursor-pointer flex items-center text-graphite-700 justify-center px-3 py-3.5 rounded-md w-full md:w-[342px] lg:w-[342px] border border-gray hover:bg-gray-100 hover:text-gray-800"
        onClick={openAllDoors}
      >
        {loading ? "Loading..." : "Open all doors"}
      </div>
      <div className="py-3" />
      <button
        disabled={loading}
        className={classNames(
          markAllAsComplete ? "bg-success-200" : "bg-success-400",
          "cursor-pointer flex items-center justify-center text-white px-3 py-3.5 rounded-md w-full md:w-[342px] lg:w-[342px] hover:bg-success-500"
        )}
        onClick={handleMarkAllAsComplete}
      >
        {loading ? "Loading..." : "Mark all as complete"}
      </button>
    </div>
  );
}

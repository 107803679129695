import { useMutation } from "@apollo/client";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardProvider from "../providers/dashboard.provider";
import type { GroceryItem } from "../services/grocery.service";
import {
  GROCERY_ORDER_BY_ID_QUERY,
  GROCERY_ORDERS_QUERY,
  UPDATE_GROCERY_ORDER_MUTATION,
  useGroceryOrder,
} from "../services/grocery.service";
import Spinner from "../components/loading";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

type OrderCardProps = {
  orderNumber: string;
  podName: string;
};

type CustomerCardProps = {
  userName: string;
  phoneNumber: string;
  email: string;
};

type ItemCardProps = {
  items: GroceryItem[];
  orderId: string;
};

const OrderCard = ({ orderNumber, podName }: OrderCardProps) => (
  <div className="bg-white rounded-lg shadow p-6 w-1/2">
    <div className="space-y-2">
      <p className="text-gray-600">
        Cubbi Order: <span className="font-bold">{orderNumber}</span>
      </p>
      <p className="text-gray-600">
        Pod Name: <span className="font-bold">{podName}</span>
      </p>
    </div>
  </div>
);

const CustomerCard = ({ userName, phoneNumber, email }: CustomerCardProps) => (
  <div className="bg-white rounded-lg shadow p-6 w-1/2">
    <div className="space-y-2">
      <p className="text-gray-600">
        User Name: <span className="font-bold">{userName}</span>
      </p>
      <p className="text-gray-600">
        Phone Number: <span className="font-bold">{phoneNumber}</span>
      </p>
      <p className="text-gray-600">
        Email: <span className="font-bold">{email}</span>
      </p>
    </div>
  </div>
);

const ItemCard = ({ items, orderId }: ItemCardProps) => {
  const { data } = useGroceryOrder({ id: orderId });
  const [updateBooking] = useMutation<{ updateBooking: { ok: boolean } }>(
    UPDATE_GROCERY_ORDER_MUTATION,
    {
      refetchQueries: [GROCERY_ORDERS_QUERY, GROCERY_ORDER_BY_ID_QUERY],
    }
  );
  const [loading, setLoading] = useState<boolean>(false);

  const isDelivered = useMemo(() => {
    return !!data?.groceryOrder?.bookings[0]?.deliveredAt;
  }, [data]);

  async function handleMarkAsDelivered() {
    setLoading(true);
    try {
      await updateBooking({
        variables: { id: orderId, input: { delivered: true } },
      });
    } catch (e) {
      alert("Error marking as delivered");
    }
    setLoading(false);
  }

  return (
    <div className="bg-white rounded-lg shadow p-6 min-w-[600px]">
      <div className="flex justify-between mb-4">
        <h2 className="text-lg font-medium">Items</h2>
        <p>Total Item Quantity: {items.length}</p>
      </div>
      <div className="space-y-6">
        {items?.map((item) => (
          <div key={item.id} className="flex items-start justify-between">
            <div>
              <p className="font-medium">{item.product.name}</p>
              <p className="text-sm text-gray-500">1lb bunch</p>
            </div>
            <div className="flex items-center gap-8">
              <span>x{item.quantity}</span>
              <span>Qty. {item.quantity}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6">
        <button
          onClick={handleMarkAsDelivered}
          disabled={loading || isDelivered}
          className="w-full bg-veggie text-white py-3 px-4 rounded-3xl hover:bg-teal-800 disabled:opacity-70 flex items-center justify-center gap-2"
        >
          {loading ? (
            "Loading..."
          ) : (
            <>
              {isDelivered && (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              )}
              {isDelivered ? "Delivered" : "Mark as delivered"}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default function GroceryOrderById() {
  const { id } = useParams();
  const { data, loading, error } = useGroceryOrder({ id });

  if (loading) return <Spinner />;
  if (error) return <div>Error loading order</div>;
  if (!data?.groceryOrder) return <div>No order found</div>;

  const { id: orderId, fridge, host, bookings } = data.groceryOrder;
  const userName = `${host.firstName} ${host.lastName}`;
  const items = bookings[0]?.bookingitemsSet || [];

  return (
    <DashboardProvider>
      <div className="mx-auto" style={{ maxWidth: "654px" }}>
        <div className="flex gap-4 p-4">
          <OrderCard orderNumber={orderId} podName={fridge.name} />
          <CustomerCard
            userName={userName}
            phoneNumber={host.phone}
            email={host.email}
          />
        </div>
        <div className="px-4">
          <ItemCard items={items} orderId={orderId} />
        </div>
      </div>
    </DashboardProvider>
  );
}

import { useMutation } from "@apollo/client";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingCards from "../components/booking-cards";
import CubbiButtons from "../components/cubbi-buttons";
import DeterminingCubbi from "../components/determining-cubbi";
import EmptyCubbi from "../components/empty-cubbi";
import FaultyCubbi from "../components/faulty-cubbi";
import Loading from "../components/loading";
import OnlineStatus from "../components/online-status";
import { AuthContext } from "../providers/auth.provider";
import {
  MARK_ALL_AS_DELIVERED,
  useDriverAppOrders,
} from "../services/bookings.service";
import { OPEN_ALL_DOORS } from "../services/fridge.service";
import { Booking, Fridge } from "../types";
import { classNames } from "../utils/classNames";
import { convertCubbiPosition } from "../utils/convert-cubbi-position";
import DeterminingCubbiGNG from "../components/determining-cubbi-gng";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";
import LargeCubbi from "../components/large-cubbi";
import { OPEN_CUBBI } from "../services/fridge.service";
import { useFridgeData } from "../services/locations.service";

interface FromAdminView {
  fridge: Fridge;
}

export default function AdminLocationView() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const { user } = useContext(AuthContext);
  const [selectedCubbi, setSelectedCubbi] = useState<{
    id: String;
    groupOrder: boolean;
  }>({ id: "", groupOrder: false });
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const [loading, setLoading] = useState<boolean>(false);
  const todayAt0 = useMemo(() => {
    return moment().set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [selectedCubbi]);

  const location = useLocation();
  const state = location.state as FromAdminView;
  const { isOnline, name, location: position } = state.fridge;
  const { id } = useParams();
  const { data } = useFridgeData(id);
  const cubbis =
    data &&
    data.cubbiSet &&
    data?.cubbiSet.edges &&
    getNodeDataFromEdges(data.cubbiSet.edges).sort(
      (a, b) => +a.position.split("_")[1] - +b.position.split("_")[1]
    );

  const navigate = useNavigate();
  const startDate = useMemo(() => {
    const yesterday = moment().set({
      hour: 9,
      minutes: 50,
      seconds: 0,
      milliseconds: 0,
    });
    if (!moment().isDST() && position && position.city === "Calgary") {
      return yesterday.format("YYYY-MM-DDT10:01:00.000");
    } else {
      return yesterday.format("YYYY-MM-DDT09:01:00.000");
    }
  }, []);

  const endDate = useMemo(() => {
    const startOfWeekMinusThree = moment();
    let returnValue = startOfWeekMinusThree
      .startOf("week")
      .subtract(3, "days")
      .set({ hour: 9, minutes: 50, second: 0, millisecond: 0 });
    if (!moment().isDST() && position && position.city === "Calgary") {
      return returnValue.format("YYYY-MM-DDT07:50:00.000");
    } else {
      return returnValue.format("YYYY-MM-DDT08:50:00.000");
    }
  }, []);

  const {
    bookings,
    refetch,
    loading: ordersLoading,
  } = useDriverAppOrders(id as number | undefined, startDate, endDate);

  const [markAllAsComplete] = useState(false);
  const [markAsComplete] = useMutation<{ updateFridge: { ok: boolean } }>(
    MARK_ALL_AS_DELIVERED
  );
  const [openFridgeAll] = useMutation<{ openFridgeAll: { succes: boolean } }>(
    OPEN_ALL_DOORS
  );
  const [openCubbi, { loading: loadingOpen }] = useMutation<{
    openCubbi: { success: boolean };
  }>(OPEN_CUBBI);

  const fridgeId = state.fridge.id;

  const bookingsMap = bookings?.reduce(
    (entryMap, e) =>
      entryMap.set(e.cubbi.position, [
        ...(entryMap.get(e.cubbi.position) || []),
        e,
      ]),
    new Map()
  );

  async function handleMarkAllAsComplete() {
    const today = moment();
    setLoading(true);
    try {
      const { data } = await markAsComplete({
        variables: {
          id: fridgeId,
          complete: today,
        },
      });
      if (data) {
        refetch();
      } else {
        alert("An error occurred");
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  }

  async function openAllDoors() {
    setLoading(true);
    try {
      const today = moment();
      const data = await openFridgeAll({
        variables: { id, user: user?.email, originTime: today },
      });
      if (data) {
      } else {
        alert("Error something went wrong");
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  }
  async function onPressOpenCubbi() {
    if (!user) {
      alert("You must be logged in to open a cubbi");
      return;
    }
    if (!selectedCubbi) {
      alert("No cubbi selected");
      return;
    }
    const bookingsFromSelected = bookingsMap.get(selectedCubbi.id);
    const cubbiId = bookingsFromSelected[0].cubbi.id;
    const bookingId = bookingsFromSelected[0].id;
    const email = user.email;
    const originTime = moment().toISOString(true);
    try {
      await openCubbi({
        variables: {
          input: {
            cubbiId,
            user: email,
            originTime,
            bookingId,
          },
        },
      });
    } catch (e) {
      console.log(e);
      alert("Failed to open cubbi");
    }
  }
  return (
    <div className="bg-white min-h-screen">
      <header className="flex flex-row justify-between items-center px-4 border-b py-4 border-graphite-200">
        <ChevronLeftIcon
          className="w-6 h-6 text-graphite-600 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <div className="flex flex-row items-center">
          <OnlineStatus status={isOnline} />
          <span className="font-bold text-lg">{name}</span>
        </div>
        <div />
      </header>
      {ordersLoading ? (
        <Loading />
      ) : (
        <main className="lg:grid-cols-3 grid grid-cols-1 px-4 py-4 h-fit justify-center justify-items-center">
          {!isMobile && (
            <CubbiButtons
              loading={loading}
              handleMarkAllAsComplete={handleMarkAllAsComplete}
              markAllAsComplete={markAllAsComplete}
              openAllDoors={openAllDoors}
            />
          )}
          {selectedCubbi && selectedCubbi.id && isMobile && bookingsMap && (
            <>
              <BookingCards
                bookings={bookingsMap.get(selectedCubbi.id)}
                today={todayAt0}
                refetch={refetch}
                isMobile={isMobile}
                isGroupOrder={selectedCubbi.groupOrder}
              />
              <div className="w-full bg-white border-graphite-200 max-w-[342px] mt-[-16px]">
                <button
                  onClick={onPressOpenCubbi}
                  disabled={loadingOpen}
                  className="flex items-center text-white bg-[#16A951] justify-center px-3 py-3 rounded-md w-full  md:w-[342px] lg:w-[442px] border border-gray"
                >
                  {loading ? "Opening..." : "Open Pod"}
                </button>
                <div className="border-b-2 py-2" />
              </div>
              <div className="py-2" />
            </>
          )}
          <div className="grid grid-cols-2 gap-3 items-center w-[342px] col-end-auto h-fit">
            {data && data.deliveryNotes && (
              <>
                <div className="-mb-3 font-bold">Delivery instructions</div>
                <div className="rounded-md h-fit col-span-2 border-graphite-200 border-2 px-2 py-2">
                  <div>{data?.deliveryNotes}</div>
                </div>
              </>
            )}
            {cubbis &&
              cubbis.map((cubbi, i) => {
                const cubbisFromMap: Booking[] = bookingsMap.get(
                  cubbi.position
                );
                const cubbisFromMapGNG = cubbisFromMap
                  ? cubbisFromMap.filter((e) => e.cubbi.use === "A_4")
                  : [];
                const cubbisFromMapPreorder = cubbisFromMap
                  ? cubbisFromMap.filter((e) => e.cubbi.use !== "A_4")
                  : [];
                const use = cubbi.use === "A_4" ? "G" : "P";
                const cubbiNumber = convertCubbiPosition(cubbi.position, use);
                return (
                  <div
                    key={i}
                    className={classNames(
                      cubbi.position === "A_12" ? "col-span-2" : undefined
                    )}
                    style={{ position: "relative" }}
                  >
                    {cubbi.position === "A_12" && !cubbisFromMap && (
                      <div className="bg-graphite-50 rounded-md h-24">
                        <div className="font-bold pl-2 pt-1 text-graphite-600 text-sm">
                          {cubbi.groupOrder ? "Group Order" : "Communal"}
                        </div>
                        <div className="grid items-center justify-center"></div>
                      </div>
                    )}
                    {cubbi.isAvailable &&
                      cubbi.position !== "A_12" &&
                      cubbisFromMapPreorder &&
                      cubbisFromMapPreorder.length > 0 && (
                        <DeterminingCubbi
                          bookings={cubbisFromMapPreorder}
                          today={todayAt0}
                          setSelectedCubbi={setSelectedCubbi}
                        />
                      )}
                    {cubbi.isAvailable &&
                      cubbisFromMapGNG &&
                      cubbi.position !== "A_12" &&
                      cubbisFromMapGNG.length > 0 && (
                        <DeterminingCubbiGNG
                          bookings={cubbisFromMapGNG}
                          today={todayAt0}
                          setSelectedCubbi={setSelectedCubbi}
                        />
                      )}
                    {cubbi.isAvailable &&
                      cubbisFromMapPreorder &&
                      cubbisFromMapPreorder.length > 0 &&
                      cubbi.position === "A_12" && (
                        <LargeCubbi
                          bookings={cubbisFromMapPreorder}
                          today={todayAt0}
                          setSelectedCubbi={setSelectedCubbi}
                          groupOrder={cubbi.groupOrder}
                        />
                      )}
                    {cubbi.position !== "A_12" &&
                      cubbi.isAvailable &&
                      !cubbisFromMap && <EmptyCubbi />}
                    {!cubbi.isAvailable && cubbi.position !== "A_12" && (
                      <FaultyCubbi />
                    )}
                    {cubbi.position !== "A_12" && (
                      <div
                        style={{ position: "absolute", left: 8, top: 4 }}
                        className="text-graphite-600 text-sm"
                      >
                        {cubbiNumber}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          {isMobile && (
            <CubbiButtons
              handleMarkAllAsComplete={handleMarkAllAsComplete}
              markAllAsComplete={markAllAsComplete}
              openAllDoors={openAllDoors}
              loading={loading}
            />
          )}
          {selectedCubbi && selectedCubbi.id && !isMobile && bookingsMap && (
            <BookingCards
              bookings={bookingsMap.get(selectedCubbi.id)}
              today={todayAt0}
              refetch={refetch}
              isMobile={isMobile}
              isGroupOrder={selectedCubbi.groupOrder}
            />
          )}
        </main>
      )}
    </div>
  );
}

import { ReactNode } from "react";
import DriverNavigation from "../components/driver-navigation";

interface Props {
  children: ReactNode;
}

export default function DriverDashboardProvider({ children }: Props) {
  return (
    <>
      <DriverNavigation />
      {children}
    </>
  );
}

import { useEffect } from "react";
import smartlookClient from "smartlook-client";
import { useMe } from "../services/user.service";

interface Props {
  children: React.ReactNode;
}

export default function SmartlookProvider({ children }: Props) {
  const { me: user } = useMe();
  useEffect(() => {
    if (!user) return;
    if (process.env.NODE_ENV === "production") {
      smartlookClient.init("88b3a35e30184c85b0f2a0cd67714046806814fc", {
        region: "us",
      });

      smartlookClient.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [user]);
  return <>{children}</>;
}

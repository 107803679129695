export const TOKEN = "token";
export const REFRESH_TOKEN = "refresh_token";
export const FRIDGE = "fridge";
export const BOOKING_DAYS = "booking_days";

export enum NotificationType {
  Success,
  Error,
  Warning,
  Info,
}

export enum SelectionType {
  A_1 = "A_1",
  A_2 = "A_2",
  A_3 = "A_3",
}

export enum ErrorCode {
  Unique = "unique",
  PasswordShort = "password_too_short",
  PasswordCommon = "password_too_common",
  PasswordNumeric = "password_entirely_numeric",
}


export enum CubbiType {
  A_0 = 1,
  A_1 = 2,
  A_2 = 3,
  A_3 = 4,
  A_4 = 5,
  A_5 = 6,
  A_6 = 7,
  A_7 = 8,
  A_8 = 9,
  A_9 = 10,
  A_10 = 11,
  A_11 = 12,
  A_12 = 13,
}


import { gql, useQuery } from "@apollo/client";

export interface GroceryItem {
  id: string;
  product: {
    id: string;
    name: string;
  };
  quantity: number;
}

export interface GroceryOrder {
  id: string;
  note: string | null;
  fridge: {
    id: string;
    name: string;
  };
  host: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  bookings: Array<{
    deliveredAt: string | null;
    bookingitemsSet: GroceryItem[];
  }>;
}

export interface IEdge<T> {
  cursor?: number | string;
  node: T;
}

export interface GroceryOrdersResponse {
  driverAppGroceryOrders: GroceryOrder[];
}

export interface GroceryOrdersQueryVariables {
  city: string;
  date: string;
}

export const GROCERY_ORDERS_QUERY = gql`
  query driverAppGroceryOrders($city: String!, $date: DateTime!) {
    driverAppGroceryOrders(city: $city, date: $date) {
      id
      note
      fridge {
        id
        name
      }
      host {
        firstName
        lastName
        phone
        email
      }
      bookings {
        deliveredAt
        items {
          edges {
            node {
              name
              quantity
            }
          }
        }
      }
    }
  }
`;

export interface GroceryOrderResponse {
  groceryOrder: GroceryOrder;
}

export interface GroceryOrderQueryVariables {
  id?: string;
}

export const GROCERY_ORDER_BY_ID_QUERY = gql`
  query groceryOrder($id: Int!) {
    groceryOrder(id: $id) {
      id
      note
      fridge {
        id
        name
      }
      host {
        firstName
        lastName
        phone
        email
      }
      bookings {
        deliveredAt
        bookingitemsSet {
          id
          product {
            id
            name
          }
          quantity
        }
      }
    }
  }
`;

export const UPDATE_GROCERY_ORDER_MUTATION = gql`
  mutation updateGroceryOrder($id: Int!, $input: GroceryOrderInput!) {
    updateGroceryOrder(id: $id, input: $input) {
      ok
    }
  }
`;

export default function useGroceryOrders(
  variables: GroceryOrdersQueryVariables
) {
  const { data, loading, error } = useQuery<GroceryOrdersResponse>(
    GROCERY_ORDERS_QUERY,
    {
      variables,
    }
  );
  return { data, loading, error };
}

export function useGroceryOrder(variables: GroceryOrderQueryVariables) {
  const { data, loading, error } = useQuery<GroceryOrderResponse>(
    GROCERY_ORDER_BY_ID_QUERY,
    {
      variables,
      skip: !variables.id,
    }
  );
  return { data, loading, error };
}

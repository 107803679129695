import { createBrowserRouter } from "react-router-dom";
import Login from "./screens/login";

const LOGIN_ROUTER = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
]);

export default LOGIN_ROUTER;

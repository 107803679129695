interface Props {
  status: boolean;
  className?: string;
}

export default function OnlineStatus({ status, className }: Props) {
  return (
    <div className={`pr-2 ${className}`}>
      {status === true ? (
        <div className="rounded-full bg-[#1AC960] h-[10px] w-[10px]" />
      ) : (
        <div className="rounded-full bg-white border-[#6D6F6E] border-[1px] h-[10px] w-[10px]" />
      )}
    </div>
  );
}

import moment, { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import { Booking } from "../types";
import { classNames } from "../utils/classNames";
import { getItemSum } from "../utils/sum-items";
import EmptyCubbi from "./empty-cubbi";

interface Props {
  bookings: Booking[];
  today: Moment;
  setSelectedCubbi: Dispatch<
    SetStateAction<{
      id: String;
      groupOrder: boolean;
    }>
  >;
  groupOrder: boolean;
}

export default function LargeCubbi({
  bookings,
  today,
  setSelectedCubbi,
  groupOrder,
}: Props) {
  if (bookings.length === 0) {
    return <EmptyCubbi />;
  }

  const notDeliveredCommunal = bookings.filter(
    (e) => !e.deliveredAt && !e.cubbi.groupOrder
  ); //Deliver
  const notDeliveredGroup = bookings.filter(
    (e) => !e.deliveredAt && e.cubbi.groupOrder
  );
  const pastBookingsCommunal = bookings.filter(
    (e) =>
      moment(e.endAt).isSameOrBefore(today, "day") &&
      e.deliveredAt &&
      !e.completed &&
      !e.cubbi.groupOrder //Remove or Move
  );
  const pastBookingsGroup = bookings.filter(
    (e) =>
      moment(e.endAt).isSameOrBefore(today, "day") &&
      e.deliveredAt &&
      !e.completed &&
      e.cubbi.groupOrder //Remove or Move
  );

  const deliveredBookingsCommunal = bookings.filter(
    (e) =>
      moment(e.endAt).isAfter(today, "day") &&
      e.deliveredAt &&
      !e.cubbi.groupOrder //delivered
  );

  const deliveredBookingsGroup = bookings.filter(
    (e) =>
      moment(e.endAt).isAfter(today, "day") &&
      e.deliveredAt &&
      e.cubbi.groupOrder
  );
  const removeExistsCommunal = pastBookingsCommunal.find(
    (i) => i.cubbi.use === "A_4"
  );
  const removeExistsGroup = pastBookingsGroup.find(
    (i) => i.cubbi.use === "A_4"
  );

  return (
    <div
      className={classNames(
        "w-full",
        (groupOrder ? removeExistsGroup : removeExistsCommunal)
          ? "bg-destructive-50 border-destructive-500"
          : (groupOrder && pastBookingsGroup.length > 0) ||
            (!groupOrder && pastBookingsCommunal.length > 0)
          ? "bg-warning-50 border-warning-500"
          : (groupOrder && notDeliveredGroup.length > 0) ||
            (!groupOrder && notDeliveredCommunal.length > 0)
          ? "bg-blue-50 border-blue-600"
          : (groupOrder && deliveredBookingsGroup.length > 0) ||
            (!groupOrder && deliveredBookingsCommunal.length > 0)
          ? "bg-success-50 border-success-600"
          : "bg-graphite-50 border-none",
        "flex border-2  rounded-md  w-[165px] items-center justify-center relative py-6"
      )}
      style={{ minHeight: "6rem" }}
      onClick={() =>
        setSelectedCubbi({
          id: bookings[0].cubbi.position,
          groupOrder: groupOrder,
        })
      }
    >
      <div className="font-bold pl-2 text-graphite-600 text-sm flex absolute top-0 left-0">
        {groupOrder ? "Group order" : "Communal"}
      </div>
      <div className="flex flex-col items-center">
        {groupOrder &&
          deliveredBookingsGroup.map((e, i) => {
            return (
              <div className="flex flex-row items-center" key={i}>
                <div className="text-success-900 font-bold">Delivered</div>
                <div className="font-base text-success-900">
                  &nbsp;{"#" + e.id}
                </div>
                <div className="font-base text-success-900">
                  {" - " + getItemSum(e)}
                </div>
              </div>
            );
          })}
        {!groupOrder &&
          deliveredBookingsCommunal.map((e, i) => {
            return (
              <div className="flex flex-row items-center" key={i}>
                <div className="text-success-900 font-bold">Delivered</div>
                <div className="font-base text-success-900">
                  &nbsp;{"#" + e.id}
                </div>
                <div className="font-base text-success-900">
                  {" - " + getItemSum(e)}
                </div>
              </div>
            );
          })}
        {groupOrder &&
          notDeliveredGroup.map((e, i) => {
            return (
              <div className="flex flex-row items-center" key={i}>
                <div className="font-bold text-graphite-900">Deliver</div>
                <div className="font-base text-graphite-900">
                  &nbsp;{"#" + e.id}
                </div>
                <div className="font-base text-graphite-900 pl-1">
                  {" - " + getItemSum(e)}
                </div>
              </div>
            );
          })}
        {!groupOrder &&
          notDeliveredCommunal.map((e, i) => {
            return (
              <div className="flex flex-row items-center" key={i}>
                <div className="font-bold text-graphite-900">Deliver</div>
                <div className="font-base text-graphite-900">
                  &nbsp;{"#" + e.id}
                </div>
                <div className="font-base text-graphite-900 pl-1">
                  {" - " + getItemSum(e)}
                </div>
              </div>
            );
          })}

        {groupOrder &&
          pastBookingsGroup.map((e, i) => {
            return (
              <div className="flex flex-row items-center" key={i}>
                <div className="font-bold text-graphite-900">
                  {pastBookingsGroup[i].cubbi.use === "A_4" ? "Remove" : "Move"}
                </div>
                <div className="font-base text-graphite-900">
                  &nbsp;{"#" + e.id}
                </div>
                <div className="font-base text-graphite-900 pl-1">
                  {" - " + getItemSum(e)}
                </div>
              </div>
            );
          })}
        {!groupOrder &&
          pastBookingsCommunal.map((e, i) => {
            return (
              <div className="flex flex-row items-center" key={i}>
                <div className="font-bold text-graphite-900">
                  {pastBookingsCommunal[i].cubbi.use === "A_4"
                    ? "Remove"
                    : "Move"}
                </div>
                <div className="font-base text-graphite-900">
                  &nbsp;{"#" + e.id}
                </div>
                <div className="font-base text-graphite-900 pl-1">
                  {" - " + getItemSum(e)}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

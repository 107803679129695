import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { Driver, Fridge } from "../types";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";
import OnlineStatus from "./online-status";

interface Props {
  fridges: Fridge[];
}

export interface FridgeInput {
  fridge: Fridge;
  driver: Driver[];
}

export default function LocationItems({ fridges }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
        {fridges.map((fridge) => {
          const drivers =
            fridge.drivers.edges && getNodeDataFromEdges(fridge.drivers.edges);
          return (
            <div
              className="hover:shadow-lg cursor-pointer bg-white rounded-lg flex flex-row items-center justify-between pl-4 pr-8 py-4"
              key={fridge.id}
              onClick={() =>
                navigate(`/locations/${fridge.id}`, {
                  state: { fridge },
                })
              }
            >
              <div className="h-full">
                <div className="font-bold flex flex-row items-center">
                  <OnlineStatus status={fridge.isOnline} />
                  <span>{fridge.name}</span>
                </div>
                {drivers && drivers.length > 0 ? (
                  drivers.map(({ firstName, lastName }) => {
                    return (
                      <div className="text-sm font-medium flex flex-row items-center text-gray-500">
                        <OnlineStatus status={false} className="invisible" />
                        {`${firstName} ${lastName ?? ""}`}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-sm font-medium flex flex-row items-center text-destructive-600">
                    <OnlineStatus status={false} className="invisible" />
                    Unassigned
                  </div>
                )}
              </div>

              <ChevronRightIcon
                className="-mr-1 ml-2 h-5 w-5 text-graphite-300"
                aria-hidden="true"
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

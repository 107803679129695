import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ReactNode } from "react";
import { REFRESH_TOKEN as REFRESH_MUTATION } from "../services/auth.service";
import { AuthResponse } from "../types";
import { REFRESH_TOKEN, TOKEN } from "../constants";

const uri = process.env.REACT_APP_API_URL;
const key = process.env.VITE_GUEST_API_KEY;

export function getJwtToken() {
  return localStorage.getItem(TOKEN);
}

export function setJwtToken(token: string) {
  localStorage.setItem(TOKEN, token);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

export function setRefreshToken(token: string) {
  localStorage.setItem(REFRESH_TOKEN, token);
}

async function getNewToken() {
  const refreshToken = getRefreshToken();
  const { data } = await client.mutate<{
    refreshToken: AuthResponse;
  }>({
    mutation: REFRESH_MUTATION,
    variables: { refreshToken },
  });
  setRefreshToken(data?.refreshToken.refreshToken ?? "");
  setJwtToken(data?.refreshToken.token ?? "");
  return `JWT ${data?.refreshToken.token}`;
}

function createLink() {
  const httpLink = new HttpLink({
    uri,
  });
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => {
      const token = getJwtToken();
      return {
        headers: {
          authorization: token ? `JWT ${token}` : undefined,
          "X-API-KEY": key,
        },
      };
    });
    return forward(operation);
  });
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.message) {
          case "You do not have permission to perform this action":
            return fromPromise(
              getNewToken()
                .then((token) => {
                  const oldHeaders = operation.getContext().headers;
                  operation.setContext(async () => {
                    return {
                      headers: {
                        ...oldHeaders,
                        authorization: token,
                      },
                    };
                  });
                })
                .catch((error) => {
                  console.error("Error refreshing token", error);
                  setJwtToken("");
                  setRefreshToken("");
                  return;
                })
            ).flatMap(() => {
              // retry the request, returning the new observable
              return forward(operation);
            });
        }
      }
    }
  });
  return ApolloLink.from([errorLink, authLink, httpLink]);
}

function createApolloClient() {
  return new ApolloClient({
    link: createLink(),
    cache: new InMemoryCache(),
  });
}

interface Props {
  children: ReactNode;
}

export const client = createApolloClient();

export function GraphQLProvider({ children }: Props) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

import { CubbiSize } from "../types";

export function convertCubbiPosition(
  position: string,
  use?: string,
  size?: CubbiSize
) {
  if (size === CubbiSize.LITE) {
    return `Cubbi Lite ${use ?? ""}`.trim();
  }

  const regex = /^([A-Z]_)(\d+)$/;
  const match = position.match(regex);
  if (match) {
    const number = parseInt(match[2], 10);
    const incrementedNumber = number + 1;
    if (incrementedNumber >= 1 && incrementedNumber <= 12) {
      return `Cubbi ${use ?? ""} #${incrementedNumber}`;
    }
  }
  return "Communal";
}

import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import CityDropdownFilter from "../components/city-dropdown-filter";
import { DeliveryCard } from "../components/delivery-card";
import DashboardProvider from "../providers/dashboard.provider";
import { useUndeliveredBookings } from "../services/bookings.service";
import Spinner from "../components/loading";

export default function AllDeliveries() {
  const [selectedCity, setSelectedCity] = useState<string>("Calgary");
  const cities = ["Calgary", "Saskatoon"];

  const currentDate = useMemo(() => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date.toLocaleString("sv").replace(" ", "T");
  }, []);

  const startDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 6);
    date.setHours(0, 0, 0, 0);
    return date.toLocaleString("sv").replace(" ", "T");
  }, []);

  const { bookings, loading, error, loadMore, hasNextPage } =
    useUndeliveredBookings(startDate, currentDate, selectedCity);

  const observerTarget = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage && !loading) {
        loadMore();
      }
    },
    [loadMore, hasNextPage, loading]
  );

  useEffect(() => {
    const element = observerTarget.current;
    if (!element) return;

    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1.0,
    });

    observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [handleObserver]);

  return (
    <DashboardProvider>
      {error ? (
        <div>Error loading deliveries</div>
      ) : (
        <div className="px-8 py-4">
          <CityDropdownFilter
            items={cities}
            title={selectedCity}
            functionToSend={setSelectedCity}
            changed
            width="w-38"
            widthOfDropdown="w-48"
            height="h-auto"
          />
          {bookings.length > 0 ? (
            <>
              <div className="mt-4 grid grid-cols-auto-fit gap-4 auto-rows-fr">
                {bookings.map((booking) => (
                  <DeliveryCard key={booking.id} booking={booking} />
                ))}
              </div>
              <div
                ref={observerTarget}
                className="h-24 flex items-center justify-center my-8"
              >
                {hasNextPage && (
                  <div className="w-full">
                    <Spinner />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="text-center text-lg font-bold">
              No deliveries found
            </div>
          )}
        </div>
      )}
    </DashboardProvider>
  );
}

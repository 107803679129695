/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/classNames";
import { Dispatch, SetStateAction } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";

interface Props {
  items: string[];
  title: string;
  functionToSend?: Dispatch<SetStateAction<string>>;
  changed: boolean;
  width: string;
  widthOfDropdown: string;
  height?: string;
  customMenuTitle?: string;
}

export default function CityDropdownFilter({
  items,
  title,
  functionToSend,
  changed,
  width,
  widthOfDropdown,
  height,
  customMenuTitle,
}: Props) {
  return (
    <Menu as="div" className={`${width} relative inline-block text-left`}>
      <div>
        <Menu.Button
          className={classNames(
            changed
              ? "inline-flex w-full justify-between rounded-3xl bg-graphite-800 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              : "inline-flex w-full justify-between rounded-3xl bg-graphite-800 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          )}
        >
          {title}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-50 mt-2 ${widthOfDropdown} ${height} origin-top-right overflow-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {items.map((item, i) => {
              return (
                <Menu.Item key={item}>
                  {({ active }) => (
                    <div
                      onClick={() => functionToSend && functionToSend(item)}
                      className={classNames(
                        i > 0 ? "border-t" : "",
                        "flex justify-between flex-row items-center cursor-pointer hover:bg-gray-100"
                      )}
                    >
                      <p
                        key={item}
                        className={classNames(
                          title === item
                            ? "text-primary-700"
                            : "text-graphite-900",
                          "block px-4 py-2 text-sm text-graphite-900 font-semibold"
                        )}
                      >
                        {i === 0 && customMenuTitle
                          ? customMenuTitle
                          : item.toString()}
                      </p>
                      {title === item && (
                        <div className="px-4">
                          <CheckIcon className="text-primary-700 h-4 w-4" />
                        </div>
                      )}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

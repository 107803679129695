import moment from "moment";
import { NotificationType, SelectionType } from "./constants";

export interface Config {
  API_URL: string;
  STRIPE_PK: string;
  CLOUDINARY_URL: string;
  PUBLIC_URL: string;
}

export interface AuthResponse {
  token: string;
  refreshToken: string;
  errors?: ErrorTypes | null;
  user: User;
}

export interface Promo {
  image: string;
  name: string;
  position: number;
  action: string;
  actionLink: string | null;
  city: string;
}

export interface EmailError {
  code: number;
  message: string;
}

export interface PasswordError {
  code: number;
  message: string;
}

export interface PasswordResetError {
  newPassword2: PasswordError[];
  success: boolean;
}

export interface PasswordResetResponse {
  errors: PasswordResetWithNew;
  success: boolean;
}

export interface PasswordResetWithNew {
  newPassword2: PasswordError[];
}

export interface RegisterErrors {
  email: EmailError[];
  password2: PasswordError[];
}

export interface RegisterResponse {
  token?: string;
  refreshToken?: string;
  errors: RegisterErrors | null;
}

export interface ResetPassword {
  success: boolean;
  errors?: ErrorTypes[] | null;
}

interface ErrorTypes {
  nonFieldErrors: ApiError[];
}
export interface ApiError {
  message: string;
  code: string;
}

export interface Notification {
  title: string;
  message: string;
  type: NotificationType;
}

export interface Entity {
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface JWT {
  email: string;
  exp: number;
  origIAt: number;
}

export interface IEdge<T> {
  cursor: number | string;
  node: T;
}

export interface QueryResult<T> {
  totalCount: number;
  edges: IEdge<T>[];
}

export interface Category {
  name: string;
  position: number;
  filteredProducts: Product[];
}
export interface ProductWithAddons {
  product: number;
  addons: number[];
  incartAddition?: number;
  incartPopup?: number;
}

export interface ProductWithAddonsAndPosition {
  product: number;
  addons: number[];
  position: number;
}
export interface ProductObj {
  product: Product;
  addons: AddonGroup[];
  incartAddition?: number;
  incartPopup?: number;
}

export interface Product extends Entity {
  id: number;
  name: string;
  availableDates: string;
  image?: string;
  description: string;
  price: number;
  discountPrice: number;
  vendor: string;
  cost: number;
  allergens: Allergen[];
  addons: AddonGroup[];
  availableStartDate: string;
  availableEndDate: string;
  isAvailable: boolean;
  isAddOn: boolean;
  cutoffTime: string;
  cutoffTimeString: string;
  sameDayCutoff: string;
  sameDayQuantity: number;
  nutritionfacts: NutritionFacts | null;
  quantity: number | null;
  totalSize: string | null;
  position: number;
  shelfLife: string | null;
  arrivalDate: string | null;
  nextArrivalDate: string;
  nextQuantity: number;
  sameDayOrders: boolean;
  prepTime: string;
}

export interface AddonGroup extends Entity {
  name: string;
  description: string;
  addons: Addon[];
  selection: SelectionType;
}

export interface Addon extends Entity {
  name: string;
  description: string;
  price: number;
  selection: SelectionType;
}

export interface Allergen {
  name: string;
}

export interface NutritionFacts {
  protein: string;
  carbs: string;
  fat: string;
  servingSize: string;
  calories: string;
}

export interface BookingItem {
  name: string;
  image?: string;
  description: string;
  price: number;
  quantity: number;
  incartAddition: { price: number };
  incartPopup: { price: number };
  productId: number;
  addons: AddonGroup[];
}

export interface CreditCard {
  last4: string;
  expYear: string;
  expMonth: string;
  paymentSet: QueryResult<PriceTotal>;
  id: number;
  paymentMethodId: string;
}
export interface User extends Entity {
  pk: number;
  lastLogin?: Date;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  verified: boolean;
  isAdmin: boolean;
  isDriver: boolean;
}

export interface Category extends Entity {
  name: string;
  position: number;
  products: QueryResult<Product>;
}

export interface Fridge extends Entity {
  id: number;
  name: string;
  serialNumber: string;
  location: Location | null;
  isOnline: boolean;
  app: string;
  deliveryFee: boolean;
  isPrivate: boolean;
  owner: Organization | null;
  image: string;
  allowWeekendAccess: boolean;
  prestock: boolean;
  cubbiSet: QueryResult<Cubbi>;
  drivers: QueryResult<Driver>;
  deliveryNotes: string;
  size: CubbiSize;
}

// A_0
// Standard (12 Cubbis)

// A_1
// Old (10 Cubbis)

// A_2
// Cubbi Lite

// A_3
// Cubbi Freemium
export enum CubbiSize {
  STANDARD = "A_0",
  OLD = "A_1",
  LITE = "A_2",
  FREEMIUM = "A_3",
}

export interface Organization extends Entity {
  name: string;
  logo: string;
  fridges: QueryResult<Fridge>;
}

export interface Location {
  floor: number;
  room: string;
  zip: string;
  longitude: number;
  state: string;
  latitude: number;
  street: string;
  city: string;
  description: string;
  building: string;
  additionalDirectionsText: string;
  additionalDirections: string;
}

export interface Driver {
  id: number;
  pk: number;
  firstName: string;
  lastName: string;
  email: string;
  driverFridges: Fridge[];
}
export interface DriversWithIds {
  userId: number | undefined;
  name: string;
}

export interface FridgesWithDrivers {
  fridges: QueryResult<Fridge>;
  drivers: Driver[];
}

export interface Cubbi {
  position: string;
  fridge: Fridge;
  id: string;
  use: string;
  communal: boolean;
  isAvailable: boolean;
  groupOrder: boolean;
}

export interface Booking extends Entity {
  orderId?: number;
  owner: User;
  payment: Payment;
  startAt: string;
  firstBooking: boolean;
  endAt: string;
  items: QueryResult<Product>;
  bookingItems: BookingItem[];
  completed: boolean;
  deliveredAt: string;
  prestockBookings?: QueryResult<payeeById>;
  cubbi: Cubbi;
  orderSet: QueryResult<OrderSet>;
  cancelledAt: string;
  reviewPopup: boolean;
  sold?: boolean;
  bookingType: string;
  bookingitemsSet: BookingSetItem[];
}

export interface OrderSet {
  refundId: string;
}

export interface Cubbi {
  fridge: Fridge;
  startAt: string;
  cubbi: Cubbi;
  communal: boolean;
  groupOrder: boolean;
}

export interface Payment extends Entity {
  creditCard: CreditCard;
  subTotal: number;
  taxTotal: number;
  discountTotal: number;
  shippingTotal: number;
  multiDayDiscount: number;
  incartAdditionsDiscount: number;
  payee: payeeById;
  captured: boolean;
}

export interface PaymentIntentRequest {
  payee: string;
  products: number[];
  fridges: number[];
  startAt: string;
  discountCode: string | null;
  creditCardDetailsId: number | null;
}

export interface PriceTotal {
  subTotal: number;
  taxTotal: number;
  discountTotal: number;
  shippingTotal: number;
  multiDayDiscount: number;
  incartAdditionsDiscount: number;
  total: number;
  payee: payeeById;
  error: String;
}

export interface DriverAppOrder {
  id: number;
  createdAt: string;
  bookings: QueryResult<Booking>;
}
export interface OrderById {
  id: number;
  refundId: string;
  createdAt: string;
  updatedAt: string;
  payment: CreditCardAccess;
  bookings: QueryResult<BookingById>;
}
export interface BookingById {
  id: number;
  startAt: string;
  createdAt: string;
  cancelledAt: string;
  cubbi: Cubbi;
  owner: User;
  orderSet: QueryResult<OrderSet>;
  bookingItems: BookingItem[];
}

export interface CreditCardAccess {
  discountTotal: string;
  subTotal: string;
  taxTotal: string;
  creditCard: CreditCard;
  shippingTotal: string;
  last4: string;
  expYear: string;
  expMonth: string;
  paymentSet: QueryResult<PriceTotal>;
  multiDayDiscount: number;
  incartAdditionsDiscount: number;
}
export interface payeeById {
  firstName: string;
  lastName: string;
}

export interface CheckDiscount {
  codeExists: boolean;
  usableCode: boolean;
  error: string;
}
export interface InCartAdditions {
  id: number;
  price: number;
  product: Product;
  active: boolean;
}

export interface Popups {
  id: number;
  title: String;
  active: boolean;
  product: Product;
  price: number;
  createdAt: String;
  updatedAt: String;
}

export interface FeaturedProduct {
  city: string;
  position: number;
  product: Product;
}

export interface InCartProducts {
  id: number;
  date: moment.Moment;
  item: Product;
}
export interface UnavailableFridgeReschedule {
  day: number;
  month: number;
  year: number;
  available: boolean;
  spaceAvailable: number;
  productAvailable: boolean;
}

export interface RescheduleBooking {
  startAt: string;
  endAt: string;
}

export interface SubscriptionReturnType {
  ok: boolean;
}

export interface Subscription {
  id: number;
  deliveryDays: string;
  mealSelection: string;
  activate: boolean;
  products: Product[];
  paymentInfo: CreditCard;
  fridge: Fridge;
}

export interface CreateReview {
  review: Review;
}

export interface Review {
  id: number;
  booking: Booking;
  deliveryRating: number;
  deliveryRatingText: string;
  productRating: number;
  productRatingText: string;
  bookingItem: ReviewProduct;
}

export interface ReviewProduct {
  product: BookingItem;
}

export interface GetReviewProduct {
  id: number;
  product: Product;
}

export interface Event {
  events: QueryResult<UserEvent>;
}

export interface UserEvent {
  createdAt: string;
  id: number;
}

export interface Prestock {
  booking: Booking;
  sold: boolean;
}

export interface QueryResultPagination<T> {
  edges: IEdge<T>[];
  totalCount?: number;
  pageInfo?: PageInfo;
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
}

export interface GroupOrder {
  id: number;
  createdAt: string;
  title?: string;
  description?: string;
  host: User;
  fridge: Fridge;
  bookings?: Booking[];
  bookingAttempts?: BookingAttempt[];
  userBookingAttempts?: BookingAttempt[];
  rating?: number;
  note?: string;
  deliveryDate?: string;
  orderDeadline?: string;
  limitPerGuest?: number;
  tokenOwner?: User;
  categories?: QueryResult<Category>;
  vendors?: QueryResult<Vendor>;
  invitedUsers: QueryResult<User>;
  payment?: Payment;
  orderedUsers?: User[];
  nonOrderedUsers?: User[];
  userBookings: Booking[] | null;
  joinedUsers?: QueryResult<User>;
  isHostOrder?: boolean;
  orderPtr: OrderPtr;
  delivery: Delivery;
}

export interface Delivery {
  id: number;
  startDatetime: string;
  trackingUrl: string;
}

export interface OrderPtr {
  id: number;
}

export interface Vendor {
  id: number;
  name: string;
  logo: string;
  filteredProductsConnection: QueryResultPagination<Product>;
}

export interface BookingAttempt {
  id: number;
  items: QueryResult<Product>;
  cubbi: Cubbi;
  owner: User;
  payment: Payment;
  isRecuring: boolean;
  isSubscription: boolean;
  startAt: string;
  endAt: string;
  deliveredAt: string;
  cancelledAt: string;
  updatedAt: string;
  createdAt: string;
  bookingitemsSet: BookingSetItem[];
  orderSet: QueryResult<OrderSet>;
}

export interface BookingSetItem {
  id: number;
  product?: Product;
  addons?: BookingSetAddonsItem[];
  quantity: number;
  booking?: Booking;
  bookingAttempt?: Booking;
  incartAddition?: number;
  incartPopup?: number;
  reviewSet?: QueryResult<Review>;
  productPrice: number;
  productDiscountPrice: number;
}

export interface BookingSetAddonsItem {
  id: number;
  name: string;
  price: number;
  addongroupSet: {
    selection: SelectionType;
  };
}

import { gql, useQuery } from "@apollo/client";
import { Booking, DriverAppOrder } from "../types";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";

export const ADMIN_ORDERS = gql`
  query driverAppOrders(
    $fridgeList: [Int]
    $endTime: DateTime
    $startTime: DateTime
    $cubbiFridgeId: Float
  ) {
    driverAppOrders(fridgeList: $fridgeList) {
      id
      createdAt
      bookings(
        startAt_Lt: $startTime
        endAt_Gt: $endTime
        cancelledAt_Isnull: true
        cantDeliver: false
        cubbi_Fridge_Id: $cubbiFridgeId
      ) {
        edges {
          node {
            id
            deliveredAt
            cancelledAt
            completed
            startAt
            endAt
            orderId
            cubbi {
              id
              position
              use
              communal
              groupOrder
            }
            bookingItems {
              name
              quantity
            }
            owner {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export function useDriverAppOrders(
  fridgeList: number | undefined,
  startDate: string,
  endDate: string
) {
  const { data, loading, error, refetch } = useQuery<{
    driverAppOrders: DriverAppOrder[];
  }>(ADMIN_ORDERS, {
    skip: fridgeList === undefined,
    variables: {
      fridgeList: [fridgeList],
      startTime: startDate,
      endTime: endDate,
      cubbiFridgeId: fridgeList,
    },
    fetchPolicy: "network-only",
  });

  const bookings = data?.driverAppOrders?.flatMap((e) => e.bookings);
  const bookingsFromEdges = bookings?.flatMap(
    (e) => e.edges && getNodeDataFromEdges(e.edges)
  );

  return { bookings: bookingsFromEdges ?? [], loading, error, refetch };
}

export const UPDATE_BOOKING = gql`
  mutation updateBooking($id: Int!, $input: BookingInput!) {
    updateBooking(id: $id, input: $input) {
      ok
    }
  }
`;

export const MARK_ALL_AS_DELIVERED = gql`
  mutation updateFridge($id: Int!, $complete: DateTime) {
    updateFridge(id: $id, input: { complete: $complete }) {
      ok
    }
  }
`;

export const GET_UNDELIVERED_BOOKINGS = gql`
  query bookings(
    $startAt_Gt: DateTime!
    $startAt_Lt: DateTime!
    $city: String!
    $first: Int
    $after: String
  ) {
    bookings(
      deliveredAt_Isnull: true
      cantDeliver: false
      startAt_Gt: $startAt_Gt
      startAt_Lt: $startAt_Lt
      cubbi_Fridge_Location_City_Iexact: $city
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          deliveredAt
          cancelledAt
          completed
          startAt
          endAt
          orderId
          bookingType
          cubbi {
            id
            position
            use
            communal
            groupOrder
            fridge {
              id
              name
              size
            }
          }
          bookingItems {
            name
            quantity
          }
          owner {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export function useUndeliveredBookings(
  startAt_Gt: string,
  startAt_Lt: string,
  city: string
) {
  const { data, loading, error, fetchMore } = useQuery<{
    bookings: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: {
        cursor: string;
        node: Booking;
      }[];
    };
  }>(GET_UNDELIVERED_BOOKINGS, {
    variables: {
      startAt_Gt,
      startAt_Lt,
      city,
      first: 10,
    },
  });

  const loadMore = () => {
    if (!data?.bookings.pageInfo.hasNextPage) return;

    return fetchMore({
      variables: {
        after: data.bookings.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          bookings: {
            ...fetchMoreResult.bookings,
            edges: [...prev.bookings.edges, ...fetchMoreResult.bookings.edges],
          },
        };
      },
    });
  };

  const bookings = data?.bookings.edges.map((edge) => edge.node) ?? [];
  const hasNextPage = data?.bookings.pageInfo.hasNextPage ?? false;

  return {
    bookings,
    loading,
    error,
    loadMore,
    hasNextPage,
  };
}

import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { Booking, Cubbi, Fridge } from "../types";
import { useLocation } from "react-router-dom";
import OnlineStatus from "../components/online-status";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";
import { classNames } from "../utils/classNames";

import FaultyCubbi from "../components/faulty-cubbi";
import { useEffect, useState } from "react";
import { convertCubbiPosition } from "../utils/convert-cubbi-position";
import { useMutation } from "@apollo/client";
import { ADMIN_ORDERS, UPDATE_BOOKING } from "../services/bookings.service";
import { LOCATIONS, useFridgeData } from "../services/locations.service";

interface LocationState {
  fridge: Fridge;
  booking: Booking;
}

export default function AvailableCubbis() {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState<Cubbi | null>(null);
  const [updateBooking, { client }] = useMutation<{
    updateBooking: { ok: boolean };
  }>(UPDATE_BOOKING);
  const [updating, setUpdating] = useState(false);

  const locationState = location.state as LocationState;

  const { id } = locationState.fridge;
  const { data } = useFridgeData(id);
  const cubbis =
    data &&
    data.cubbiSet &&
    data?.cubbiSet.edges &&
    getNodeDataFromEdges(data.cubbiSet.edges).sort(
      (a, b) => +a.position.split("_")[1] - +b.position.split("_")[1]
    );

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  async function onClickReassignBooking() {
    if (!locationState.booking.id || !modalVisible?.id || updating) {
      return;
    }
    try {
      setUpdating(true);
      await updateBooking({
        variables: {
          id: locationState.booking.id,
          input: {
            cubbi: modalVisible.id,
          },
        },
      });
      await client.refetchQueries({ include: [ADMIN_ORDERS, LOCATIONS] });
      setUpdating(false);
      setModalVisible(null);
      navigate(isMobile ? -1 : -1);
    } catch (e) {
      setUpdating(false);
      console.log(e);
      alert("Something went wrong");
    }
  }

  return (
    <>
      <div className="bg-white min-h-screen">
        <header className="flex flex-row justify-between items-center px-4 border-b py-4 border-graphite-200">
          <ChevronLeftIcon
            className="w-6 h-6 text-graphite-600 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <div className="flex flex-row items-center">
            <OnlineStatus status={data?.isOnline ?? false} />
            <span className="font-bold text-lg">
              Reassign Booking #{locationState.booking.id}
            </span>
          </div>
          <div />
        </header>

        <main className="flex justify-center my-4">
          <div className="grid grid-cols-2 gap-3 items-center w-[342px] col-end-auto">
            {data && data.deliveryNotes && (
              <>
                <div className="-mb-3 font-bold">Delivery instructions</div>
                <div className="rounded-md h-fit col-span-2 border-graphite-200 border-2 px-2 py-2">
                  <div>{data?.deliveryNotes}</div>
                </div>
              </>
            )}
            {cubbis &&
              cubbis.map((cubbi, i) => {
                const use = cubbi.use === "A_4" ? "G" : "P";
                const cubbiNumber = convertCubbiPosition(cubbi.position, use);
                return (
                  <div
                    className={classNames(
                      cubbi.position === "A_12" ? "col-span-2" : ""
                    )}
                    key={i}
                  >
                    {cubbi.position === "A_12" ? (
                      <div
                        onClick={
                          cubbi.isAvailable
                            ? () => setModalVisible(cubbi)
                            : () => null
                        }
                        className={`${
                          cubbi.isAvailable
                            ? "hover:border-2 border-indigo-600 cursor-pointer"
                            : undefined
                        } bg-graphite-50 rounded-md h-24`}
                      >
                        <div className="font-bold pl-2 pt-1  text-graphite-600 text-sm">
                          Communal
                        </div>
                      </div>
                    ) : cubbi.isAvailable ? (
                      <div
                        onClick={() => setModalVisible(cubbi)}
                        className="hover:border-2 border-indigo-600 cursor-pointer flex flex-col bg-graphite-50 rounded-md h-24 w-[165px] items-center justify-center"
                      >
                        <div>Available</div>
                        <div>{cubbiNumber}</div>
                      </div>
                    ) : (
                      <FaultyCubbi />
                    )}
                  </div>
                );
              })}
          </div>
        </main>
      </div>
      <div
        className={`relative z-10 ${modalVisible ? "" : "hidden"}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          onClick={() => setModalVisible(null)}
          className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
            modalVisible ? "opacity-100" : "opacity-0"
          }`}
        />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <div
              className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                modalVisible
                  ? "opacity-100 translate-y-0 sm:scale-100"
                  : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }`}
            >
              <div className="bg-white px-4 pb-4 pt-5">
                <div className="mt-3 text-center">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900 mx-4 text-center"
                    id="modal-title"
                  >
                    Confirm reassign Booking #{locationState.booking.id} to{" "}
                    {convertCubbiPosition(modalVisible?.position || "")}?
                  </h3>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3">
                <button
                  onClick={onClickReassignBooking}
                  type="button"
                  className="w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm  text-white shadow-sm hover:bg-indigo-700 "
                >
                  {updating ? "Confirming..." : "Confirm"}
                </button>
                <button
                  onClick={() => setModalVisible(null)}
                  type="button"
                  className="my-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
